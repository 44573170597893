<script setup lang="ts">
defineSlice({
  name: { label: 'Bento Multitenancy', group: 'Bento', layouts: ['VoixMarketing'] },
  description: '',
  preview: 'SlicesBentoMultitenancy.jpg',
  fields: {

  },
  templates: [{
    label: 'Main Example',
    fields: {

    },
  }],
})

const activated = inject('activated')
</script>

<template>
  <div class="absolute inset-0 overflow-hidden duration-1000 delay-200" :class="{ 'grayscale opacity-20': !activated }">
    <IllustrationsHallOfMirrors />
  </div>
</template>
